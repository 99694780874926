import React, { Suspense, lazy, useState } from "react";

import { useMediaQuery } from "@material-ui/core";

import { useLoyaltyRewards } from "modules/LoyaltyRewards/Data/hooks";
import { TabPanel } from "shared/Components";

import { useMembers } from "shared/hooks";
import { Tab, TabContainer } from "shared/styles";
import * as appTheme from "theme/default";

import { Content, Header } from "./style";

import Leaderboard from "../../Components/Leaderboard";
const BadgeManagement = lazy(() => import("../../Components/BadgeManagement"));
const RulesIncentives = lazy(() => import("../../Components/RulesIncentives"));
const WithdrawRequests = lazy(() => import("../../Components/WithdrawRequests"));

const LoyaltyRewards = () => {
	const [activeTab, setActiveTab] = useState(0);
	const { setUsers } = useMembers();
	const { setBadge, setRules } = useLoyaltyRewards();
	const isMobile = useMediaQuery(appTheme.default.breakpoints.down("sm"));

	return (
		<>
			<Header>Loyalty & Rewards</Header>
			<TabContainer
				variant={isMobile ? "fullWidth" : "standard"}
				activeTab={activeTab}
				onChange={(event: React.ChangeEvent, value: number) => {
					if (activeTab === 0) setUsers([]);
					else if (activeTab === 1) setBadge([]);
					else if (activeTab === 2) setRules([]);
					setActiveTab(value);
				}}
				value={activeTab}
			>
				<Tab value={0} label={"Leaderboard"} />
				<Tab value={1} label={"Badge Management"} />
				<Tab value={2} label={"Rules & Incentives"} />
				<Tab value={3} label={"Withdraw Requests"} />
			</TabContainer>

			<Content.Child>
				<TabPanel value={activeTab} index={0}>
					<Leaderboard />
				</TabPanel>
				<Suspense fallback={false}>
					<TabPanel value={activeTab} index={1}>
						<BadgeManagement />
					</TabPanel>
					<TabPanel value={activeTab} index={2}>
						<RulesIncentives />
					</TabPanel>
					<TabPanel value={activeTab} index={3}>
						<WithdrawRequests />
					</TabPanel>
				</Suspense>
			</Content.Child>
		</>
	);
};

export default LoyaltyRewards;
