export const API_URLS = {
	MEMBERS: "/globaluser/members",
	AWARD_MEMBERS: "/loyalty/award",
	BADGES: "/loyalty/badges",
	RULES: "/loyalty/rules",
	WITHDRAW_REQUESTS: "/loyalty/withdraw/requests",
	APPROVE_WITHDRAW_REQUEST: "/loyalty/withdraw/approve",
	DELETE_WITHDRAW_REQUEST: "/loyalty/withdraw/requests",
	ADMIN_METRICS: "/loyalty/admin/metrics"
};
