import { useMemo } from "react";

import { API_URLS } from "modules/LoyaltyRewards/constants";
import useHttpActions from "shared/services/api/core/useHttpActions";
import BaseConverter from "shared/services/converters/baseConverter";

import { WithdrawRequestsResponse } from "../../../../shared/types/WithdrawRequest";

const useLoyaltyRewardsService = () => {
	const actions = useHttpActions();

	return useMemo(
		() => ({
			awardMembers: async (personaIds: number[], amount: number) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.post(API_URLS.AWARD_MEMBERS, {
						personaIds,
						amount
					})
				);
			},
			getWithdrawRequests: async (offset: number, limit: number, keyword?: string) => {
				return BaseConverter.convert<WithdrawRequestsResponse>(
					await actions.get(API_URLS.WITHDRAW_REQUESTS, {
						offset,
						limit,
						keyword
					})
				);
			},
			approveWithdrawRequest: async (withdrawRequestId: string) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.put(API_URLS.APPROVE_WITHDRAW_REQUEST, {
						withdrawRequestId
					})
				);
			},
			deleteWithdrawRequest: async (withdrawRequestId: string) => {
				return BaseConverter.convert<{ success: boolean }>(
					await actions.delete(`${API_URLS.DELETE_WITHDRAW_REQUEST}/${withdrawRequestId}`)
				);
			},
			getAdminMetrics: async () => {
				return BaseConverter.convert<{ totalOutstandingPoints: number }>(await actions.get(API_URLS.ADMIN_METRICS));
			}
		}),
		[actions]
	);
};

export default useLoyaltyRewardsService;
