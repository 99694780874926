import React, { FC } from "react";

import DateFnsAdapter from "@date-io/date-fns";

import { Box } from "@material-ui/core";

import { ReactComponent as IconPointShield } from "assets/icons/point-shield.svg";
import { Receipt } from "shared/types/TransactionModel";
import { Text } from "shared/ui-kit";

const dateFns = new DateFnsAdapter();

const RightSide: FC<{ item: Receipt }> = ({ item }) => {
	const isMinus = item.transactionType === "convert" ? true : false;
	const dt = new Date(item.createdAt),
		date = dt.getDate(),
		diffDays = new Date().getDate() - date,
		diffMonths = new Date().getMonth() - dt.getMonth(),
		diffYears = new Date().getFullYear() - dt.getFullYear();

	const isToday = diffYears === 0 && diffDays === 0 && diffMonths === 0;
	const isYesterday = diffYears === 0 && diffDays === 1 && diffMonths === 0;

	return (
		<Box className="right-side">
			<Text className="date">
				{isToday
					? "Today"
					: isYesterday
					? "Yesterday"
					: dateFns.format(new Date(item.createdAt), "MMM do, yyyy" as any)}
			</Text>
			<Box className="points">
				<Text className={`points-amount ${isMinus ? "minus" : "plus"}`}>
					{isMinus ? "-" : "+"}
					{item.points}
				</Text>
				<IconPointShield />
			</Box>
		</Box>
	);
};

export default RightSide;
