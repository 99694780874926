import React, { useCallback, useEffect, useMemo, useState } from "react";

import numeral from "numeral";

import { ReactComponent as IconPointShield } from "assets/icons/point-shield.svg";
import { useLoyaltyRewards } from "modules/LoyaltyRewards/Data/hooks";
import { PageTemplate } from "modules/Manage/View/Components";
import { MenuDots, PlaceholderImage, TableWrapper } from "shared/Components";
import { TableColumnLoaderType } from "shared/Components/NewTable/Components";
import { Cell } from "shared/Components/NewTable/style";
import { useDebounce, useMembers } from "shared/hooks";
import { MembersModel, PlaceholderImageType, ProfilePhotoSizes, UserStatus } from "shared/types";
import { Button } from "shared/ui-kit";
import { getResizedImage } from "utils/serviceUtils/cdnImages";
import { formatCount } from "utils/serviceUtils/helpers";

import { Chip, PointsAmountBox } from "./style";

import AwardPointsDialog from "../../Components/AwardPointsDialog";
import RewardHistoryDialog from "../../Components/RewardHistoryDialog";

const Table = React.lazy(() => import("shared/Components/NewTable"));

const Leaderboard = () => {
	const [keyword, setKeyword] = useState("");

	const {
		setSelectedMembers,
		setAwardDialog,
		setHistoryDialog,
		getData: getLoyaltyData,
		getTotalOutstandingPoints
	} = useLoyaltyRewards();
	const { selectedMembers, awardDialog, historyDialog, totalOutstandingPoints } = getLoyaltyData();

	const { getMembers, setPage, setMemberShowPerPage, getMemberTotalCount, getData: getMembersData } = useMembers();
	const { memberShowPerPage, users, page, totalCount, loadingMembers } = getMembersData();

	const debouncedKeyword = useDebounce(keyword, 400);

	useEffect(() => {
		setSelectedMembers([]);

		return () => {
			setSelectedMembers([]);
		};
	}, [setSelectedMembers]);

	useEffect(() => {
		getMembers(
			{
				limit: memberShowPerPage,
				offset: page,
				type: "members",
				keyword: debouncedKeyword,
				filterBy: [UserStatus.ON_BOARDED, UserStatus.ARCHIVE, UserStatus.PAID_SUBSCRIPTION]
			},
			true
		);
	}, [debouncedKeyword, getMembers, memberShowPerPage, page]);

	useEffect(() => {
		getTotalOutstandingPoints();
	}, [getTotalOutstandingPoints]);

	useEffect(() => {
		getMemberTotalCount({
			type: "members",
			filterBy: [UserStatus.ON_BOARDED, UserStatus.ARCHIVE, UserStatus.PAID_SUBSCRIPTION],
			keyword: debouncedKeyword
		});
	}, [getMemberTotalCount, debouncedKeyword]);

	const handleSearch = useCallback(
		(keyword: string) => {
			if (keyword === "" || keyword.length >= 3) {
				setKeyword(keyword);
				setPage(1);
			}
		},
		[setPage]
	);

	const getOptions = useCallback(
		(rowData: MembersModel) => [
			{
				name: "See Reward History",
				onClick: () =>
					setHistoryDialog({
						open: true,
						member: rowData,
						history: null
					})
			}
		],
		[setHistoryDialog]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				dataKey: "name",
				label: <Cell.HeaderText>{formatCount(totalCount, "Community Member", "Community Members")}</Cell.HeaderText>,
				minWidth: 260,
				loaderTemplate: TableColumnLoaderType.imageWthTwoTextRows,
				Cell: ({ rowData: { firstName, lastName, photos, subscriptionType } }: { rowData: MembersModel }) => {
					const isPremium = subscriptionType === "PAID";
					const icon = photos.length ? photos[0]?.profilePicture : undefined;
					const coverImgUrl = !!icon ? getResizedImage(icon, ProfilePhotoSizes.size200x200) : undefined;

					return (
						<Cell.Wrapper>
							<Cell.ImageWrapper>
								{coverImgUrl ? (
									<Cell.Image src={coverImgUrl} />
								) : (
									<PlaceholderImage
										type={PlaceholderImageType.PROFILE_IMAGE}
										width={40}
										height={40}
										viewBox={"0 0 400 400"}
									/>
								)}
							</Cell.ImageWrapper>
							<Cell.Wrapper className="column with-image">
								<Cell.Text>
									{firstName} {lastName}
								</Cell.Text>
								<Cell.Text className="light">
									<Chip className={`chip ${isPremium ? "premium" : "free"}`}>{isPremium ? "Premium" : "Free"}</Chip>
								</Cell.Text>
							</Cell.Wrapper>
						</Cell.Wrapper>
					);
				}
			},
			{
				alignment: "left",
				dataKey: "badge",
				label: <Cell.HeaderText>Badge</Cell.HeaderText>,
				width: 80,
				Cell: ({ rowData: { loyaltyBadges } }: { rowData: MembersModel }) => {
					const icon = loyaltyBadges.length
						? loyaltyBadges.sort((badge1, badge2) => (badge1.totalNeed < badge2.totalNeed ? 1 : -1))[0]?.image
						: undefined;
					return (
						<Cell.Wrapper className="column">
							<Cell.ImageWrapper className="bg-white">{icon ? <Cell.Image src={icon} /> : null}</Cell.ImageWrapper>
						</Cell.Wrapper>
					);
				}
			},
			{
				alignment: "left",
				dataKey: "alltime",
				label: <Cell.HeaderText>All Time Points</Cell.HeaderText>,
				minWidth: 160,
				Cell: ({ rowData: { loyaltyAllTimePoints } }: { rowData: MembersModel }) => (
					<Cell.Wrapper>
						<PointsAmountBox>
							<PointsAmountBox.Value>{loyaltyAllTimePoints}</PointsAmountBox.Value>
							<PointsAmountBox.IconWrapper>
								<IconPointShield />
							</PointsAmountBox.IconWrapper>
						</PointsAmountBox>
					</Cell.Wrapper>
				)
			},
			{
				alignment: "left",
				dataKey: "current",
				label: <Cell.HeaderText>Current Balance</Cell.HeaderText>,
				minWidth: 160,
				Cell: ({ rowData: { loyaltyCurrentBalance } }: { rowData: MembersModel }) => (
					<Cell.Wrapper>
						<PointsAmountBox>
							<PointsAmountBox.Value>{loyaltyCurrentBalance}</PointsAmountBox.Value>
							<PointsAmountBox.IconWrapper>
								<IconPointShield />
							</PointsAmountBox.IconWrapper>
						</PointsAmountBox>
					</Cell.Wrapper>
				)
			},
			{
				alignment: "right",
				dataKey: "submittedAt",
				label: "",
				minWidth: 220,
				Cell: ({ rowData }: { rowData: MembersModel }) => {
					return (
						<Cell.Wrapper className="end">
							<Button
								buttonTheme="light"
								size="small"
								onClick={() =>
									setAwardDialog({
										open: true,
										members: [
											{
												label: rowData.firstName,
												profilePicture: !!rowData.photos?.length ? rowData.photos[0].profilePicture : null,
												value: rowData.personaId
											}
										]
									})
								}
							>
								Award Points
							</Button>
							<MenuDots options={getOptions(rowData)} vertical removeBg removeshadow removeSideMargin />
						</Cell.Wrapper>
					);
				}
			}
		],
		[setAwardDialog, totalCount, getOptions]
	);

	const handleChangePage = useCallback(
		(newPage: number) => {
			setPage(newPage);
		},
		[setPage]
	);

	const handleChangeRowsPerPage = useCallback(
		(newPageSize: number) => {
			setMemberShowPerPage(newPageSize);
			setPage(1);
		},
		[setMemberShowPerPage, setPage]
	);

	const onToggleCheckRow = useCallback(
		({
			isChecked,
			toggledIndex,
			checkedRows
		}: {
			isChecked: boolean;
			toggledIndex?: number;
			checkedRows?: number[];
		}) => {
			if (toggledIndex !== undefined) {
				const user = users[toggledIndex];

				setSelectedMembers(
					isChecked
						? [
								...selectedMembers,
								{
									value: user.personaId,
									label: user.firstName,
									profilePicture: user.photos?.length ? user.photos[0]?.profilePicture : undefined
								}
						  ]
						: selectedMembers.filter(m => m.value !== user.personaId)
				);
			} else if (checkedRows) {
				setSelectedMembers(
					isChecked
						? users.map(user => ({
								value: user.personaId,
								label: user.firstName,
								profilePicture: user.photos?.length ? user.photos[0]?.profilePicture : undefined
						  }))
						: []
				);
			}
		},
		[users, selectedMembers, setSelectedMembers]
	);

	const TableBlock = useMemo(
		() => (
			<TableWrapper sizes={{ horizontalTablet: 900 }}>
				<Table
					columns={tableColumns}
					data={users || []}
					loading={loadingMembers}
					checkable
					onToggleCheckRow={onToggleCheckRow}
					paginated
					totalDataCount={totalCount || 20}
					page={page}
					pageSize={memberShowPerPage}
					onChangePage={handleChangePage}
					onChangePageSize={handleChangeRowsPerPage}
				/>
			</TableWrapper>
		),
		[
			tableColumns,
			users,
			loadingMembers,
			totalCount,
			page,
			memberShowPerPage,
			handleChangePage,
			handleChangeRowsPerPage,
			onToggleCheckRow
		]
	);

	const handleCreateNew = () => {
		setAwardDialog({
			open: true,
			members: selectedMembers
		});
	};

	return (
		<>
			<PageTemplate
				title={`Total Outstanding: ${numeral(totalOutstandingPoints).format("0,0")} sats`}
				isLoading={loadingMembers}
				isNoData={!users.length}
				emptyText={"You don’t have any Members in your community yet."}
				searchPlaceholder={"Search Members"}
				onSearchUpdate={handleSearch}
				actionText={"Award Points"}
				onCreateClick={handleCreateNew}
			>
				{TableBlock}
			</PageTemplate>
			{historyDialog.open && <RewardHistoryDialog />}
			{awardDialog.open && <AwardPointsDialog />}
		</>
	);
};

export default Leaderboard;
